import React from 'react'
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export const query = graphql`
  query Sermon($slug: String!) {
    strapiSermon(slug: {eq: $slug}) {
      id
      title
      subtitle
      series
      notes
      date(formatString: "MMM Do YYYY")
      audio {
        publicURL
      }
      updated_at
      person {
        name {
          prefix
          first_name
          last_name
        }
      }
    }
    allStrapiSermon(sort: {order: ASC, fields: [date]}) {
      edges {
        node {
          id
          title
          slug
          date
        }
      }
    }
  }
`

const Sermon = ({ data }) => {
  const sermonId = data.strapiSermon.id

  let sermonIndex = -1
  data.allStrapiSermon.edges.some(s => {
    ++sermonIndex
    return s.node.id === sermonId
  })

  const prev = data.allStrapiSermon.edges[sermonIndex - 1]
  const next = data.allStrapiSermon.edges[sermonIndex + 1]
  const prevBtn = !prev ? <></> : (<NextButton sermon={prev.node} />)
  const nextBtn = !next ? <></> : (<PrevButton sermon={next.node} />)


  const sermon = data.strapiSermon
  const speakerName = !sermon.person ? `unknown` : `${sermon.person.name.prefix} ${sermon.person.name.first_name} ${sermon.person.name.last_name}`
  return (
    <Layout title="Listen Online" seoTitle={sermon.title} subtitle="HRBC">
      <section id="beliefs" className="page-section cta">
        <Container>
          <Row>
            <Col xl={9} className="mx-auto">
              <div className="cta-inner text-center rounded remark-content">

                <Row className="justify-content-between">
                  {prevBtn}
                  {nextBtn}
                </Row>

                <h1>{sermon.title}</h1>
                <h3>{sermon.subtitle}</h3>
                <h4 className="neutral small">{sermon.date} | Sermon by {speakerName}</h4>
                {sermon.audio && sermon.audio.publicURL 
                  ? <audio controls src={sermon.audio.publicURL} className="player"></audio>
                  : <p>There is no audio file available</p>}
                <ReactMarkdown source={sermon.notes} />
                <p className="text-right">
                  <small>Last updated on <Moment format="MMM Do YYYY">{sermon.updated_at}</Moment></small>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Sermon


const PrevButton = ({ sermon }) => {
  return (
    <Col>
      <Link to={`/sermons/` + sermon.slug}>
        <Row noGutters style={{ textAlign: 'right' }}>
          <Col>{sermon.title}<br />
          <span className="hide-date"><Moment format="MMM Do YYYY">{sermon.date}</Moment></span>
          </Col>
          <Col xs="auto" style={{ padding: '0 12px' }}><i className={'fa fa-chevron-right'}></i></Col>
        </Row>
      </Link>
    </Col>
  )
}

const NextButton = ({ sermon }) => {
  return (
    <Col>
      <Link to={`/sermons/` + sermon.slug}>
        <Row noGutters style={{ textAlign: 'left' }}>
          <Col xs="auto" style={{ padding: '0 12px' }}><i className={'fa fa-chevron-left'}></i></Col>
          <Col>{sermon.title}<br />
          <span className="hide-date"><Moment format="MMM Do YYYY">{sermon.date}</Moment></span>
          </Col>
        </Row>
      </Link>
    </Col>
  )
}

// next: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/></svg>")
// prev: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/></svg>")